import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic0 from '../assets/images/3dprintingcourse/0.jpg'
import pic1 from '../assets/images/3dprintingcourse/1.jpg'
import pic2 from '../assets/images/3dprintingcourse/2.jpg'
import pic3 from '../assets/images/3dprintingcourse/3.jpg'
import pic4 from '../assets/images/3dprintingcourse/4.jpg'
import pic5 from '../assets/images/3dprintingcourse/5.jpg'

import Slider from '@bit/akiran.react-slick.slider';

import GoToTop from '../components/ui/GoToTop'


const Other = (props) =>{
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2
      };

    return (
    <Layout>
        <Helmet>
            <title>Other Research - Jonathan Camargo</title>
            <meta name="description" content="Other research" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Other Research</h1>
                    </header>                    
                    <p>Great research can emerge by connecting ideas from multiple disciplines. As a curious mind, I always want to learn more and develop more skills. I enjoy exploring other research areas. This not only gives a broad spectrum of knowledge but also serves to improve my core work.</p>             
                    
                    <p> One of my favorite technologies is 3D printing and, as an advocate for open-source, I am all in the <a href="https://www.reprap.org">RepRap.org</a> movement. 
                    Since I was an instructor in Uniandes I promoted open-source 3D printing, creating workshops and a course to introduce the students to the DIY printer hardware, architecture, and usage.
                    I work with FDM, SLA, and even paste dispensing for digital PCB fabrication; I see the advantages of 3D printing as an excellent resource for education and research.                    
                    </p>                           

                    {/* <span className="image main"><img src={pic1} alt="" /></span> */}
                    <div className="container">
				    <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
				    <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />

                    <style>{cssstyle}</style>                    
                    <Slider {...settings}>
                        <div class="col-4 , pad">
                            <span className="image fit">
                            <img src={pic0}></img>
                            </span>
                        </div>
                        <div class="col-4 , pad"> 
                            <span className="image fit">
                            <img src={pic1}></img>                            
                            </span>
                        </div>
                        <div class="col-4 , pad">
                        <span className="image fit">
                            <img src={pic2}></img>
                            </span>
                        </div>
                        <div class="col-4 , pad">
                        <span className="image fit">
                            <img src={pic3}></img>
                            </span>
                        </div>
                        <div class="col-4 , pad">
                        <span className="image fit">
                            <img src={pic4}></img>
                            </span>
                        </div>
                        <div class="col-4 , pad">
                        <span className="image fit">
                            <img src={pic5}></img>
                            </span>
                        </div>
                        
                    </Slider>
                    </div>

                    <p> Since I worked in computational fluid dynamics, I learned the importance of scientific visualization. I like to play with rendering engines and environments with multiphysics simulations.         
                        This has given me resources to create better ways to illustrating experimental data and visualizations that allow me to understand complex phenomena.                        
                    </p>
                    
                    <div class="videoWrapper" style={{AspectRatio: "3 / 4"}}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Op0U3PE2L5A" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>    

                    <br></br>
                    <p> I like to combine other materials and manufacturing techniques, molding, thermoforming, and using silicone rubbers to create better shapes for wearable attachment
                        and even deformable robots and actuators.
                    </p>

                    <div class="videoWrapper" style={{AspectRatio: "3 / 4"}}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/SLwbau6JdUk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    

                    

                    <p></p>



                </div>
            </section>

            <GoToTop></GoToTop>
        </div>

    </Layout>
);
}

const cssstyle = `
.container {
  margin: 0 auto;
  padding: 0px 40px 40px 40px;  
}
.pad{
    padding: 0px 0px 20px 20px;
}
h3 {
    background: #5f9ea0;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
}
.slick-next:before, .slick-prev:before {    
}
.slick-arrow{
    box-shadow: inset 0 0 0 0px
}
`

export default Other